<template>
  <main v-if="!isLoadingLocales">
    <section class="pagehead">
      <div class="container">
        <div class="pageheadinner">
          <h1>{{ $t("submit_question_page.learn_from_dr_figo") }}</h1>
          <img
            class="pageheadhuman"
            src="../assets/images/human5.png"
            alt="swoohuman"
          >
        </div>
      </div>
    </section>
    <section class="topicmain">
      <div class="container">
        <div class="topicmainbox toicslist anssingletoics">
          <h1>{{ $t("submit_question_page.you_asked") }}</h1>
          <p>{{ askQuestion }}</p>
        </div>

        <div class="topicmainbox toicslist anssingletoics toicslisthere">
          <h1>
            {{ $t("submit_question_page.here_are_answers_may_find_helpful") }}
          </h1>
        </div>
        <div class="row">
          <div
            class="col-md-6"
            v-for="(item, index) in relatedFaqList"
            :key="index"
          >
            <router-link
              :to="
                '/learn-from-dr-figo/' +
                  item.category_id +
                  '/learn-topic/' +
                  item.id +
                  '/single-topic?topic=' +
                  item.category_title
              "
            >
              <div class="topicdetails">
                <h1 class="ellipsis">
                  {{ item.title }}
                </h1>
                <p
                  class="ellipsis-2"
                  v-html="item.content"
                />
                <i class="fa fa-caret-right" />
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="topicmain questionmain">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10">
            <div class="topicmainbox questiontopic">
              <h1>
                {{
                  $t("submit_question_page.information_doesnot_answer_question")
                }}
              </h1>
              <p v-html="$t('submit_question_page.answer_retrievel_page_description')" />
            </div>
          </div>
          <div class="col-lg-10 col-md-12">
            <div class="questionform">
              <form>
                <div class="row">
                  <div class="col-lg-9 col-md-10">
                    <label>{{
                      $t("submit_question_page.question_to_send_to_dr_figo")
                    }}</label>
                    <input
                      type="text"
                      name=""
                      placeholder="[What happens when I have my period?]"
                      v-model="askQuestion"
                      @change="saveQuestion()"
                    >
                    <p v-html="$t('submit_question_page.need_to_now_two_things')" />
                  </div>
                </div>
                <label class="mt-5">{{
                  $t("submit_question_page.required_questuons")
                }}</label>
                <div class="row">
                  <div class="col-md-4 col-lg-3">
                    <label>{{ $t("submit_question_page.gender") }}</label>
                    <ul>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="gender"
                          value="Female"
                          v-model="requiredQuestion.gender_value"
                        ><span class="checkmark" /><b>
                          {{ $t("submit_question_page.female") }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="gender"
                          value="Male"
                          v-model="requiredQuestion.gender_value"
                        ><span class="checkmark" /><b>
                          {{ $t("submit_question_page.male") }}
                        </b>
                      </li>
                      <li
                        class="customradio"
                        v-if="gender_has_other_option"
                      >
                        <input
                          type="radio"
                          name="gender"
                          value="Other gender"
                          v-model="requiredQuestion.gender_value"
                        ><span class="checkmark" /><b>
                          {{ $t("submit_question_page.other_gender") }}
                        </b>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6 col-lg-3">
                    <label>{{ $t("submit_question_page.age") }}</label>
                    <ul>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="age"
                          value="Younger than 15 years old"
                          v-model="requiredQuestion.age_value"
                        ><span class="checkmark" /><b>
                          {{ $t("submit_question_page.younger_than_15_years") }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="age"
                          value="15-19 years old"
                          v-model="requiredQuestion.age_value"
                        ><span class="checkmark" /><b>{{
                          $t("submit_question_page.15_19_years_old")
                        }}</b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="age"
                          value="20-24 years old"
                          v-model="requiredQuestion.age_value"
                        ><span class="checkmark" /><b>
                          {{ $t("submit_question_page.20_24_years_old") }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="age"
                          value="25-34 years old"
                          v-model="requiredQuestion.age_value"
                        ><span class="checkmark" /><b>
                          {{ $t("submit_question_page.25_34_years_old") }}
                        </b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="age"
                          value="35-50 years old"
                          v-model="requiredQuestion.age_value"
                        ><span class="checkmark" /><b>{{
                          $t("submit_question_page.35_50_years_old")
                        }}</b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="age"
                          value="Older than 50 years old"
                          v-model="requiredQuestion.age_value"
                        ><span class="checkmark" /><b>
                          {{ $t("submit_question_page.older_than_50_years") }}
                        </b>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <label>{{
                      $t("submit_question_page.are_you_pregnant_right_now")
                    }}</label>
                    <ul>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="pregnant"
                          value="1"
                          v-model="requiredQuestion.is_pregnant"
                        ><span class="checkmark" /><b>{{
                          $t("submit_question_page.yes")
                        }}</b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="pregnant"
                          value="0"
                          v-model="requiredQuestion.is_pregnant"
                        ><span class="checkmark" /><b>{{
                          $t("submit_question_page.no")
                        }}</b>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <label>{{ $t("submit_question_page.are_you_sexually_active")
                    }}<small>{{
                      $t("submit_question_page.this_means_having_sex")
                    }}</small></label>
                    <ul>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="sexually"
                          value="1"
                          v-model="requiredQuestion.is_sexually_active"
                        ><span class="checkmark" /><b>{{
                          $t("submit_question_page.yes")
                        }}</b>
                      </li>
                      <li class="customradio">
                        <input
                          type="radio"
                          name="sexually"
                          value="0"
                          v-model="requiredQuestion.is_sexually_active"
                        ><span class="checkmark" /><b>{{
                          $t("submit_question_page.no")
                        }}</b>
                      </li>
                    </ul>
                  </div>
                </div>
                <p
                  class="error"
                  v-if="errorShow"
                >
                  {{
                    $t(
                      "submit_question_page.please_answer_all_of_the_questions"
                    )
                  }}
                </p>
                <button
                  type="button"
                  :disabled="isActive"
                  @click="onSubmit()"
                >
                  {{ $t("submit_question_page.submit_question_to_dr_figo") }}
                  <i class="fa fa-caret-right" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <img
        class="formhuman"
        src="../assets/images/human61.png"
        alt="swoohuman"
      >
    </section>
  </main>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      isActive: false,
      askQuestion: "",
      gender_has_other_option: false,
      requiredQuestion: {},
      errorShow: false,
      relatedFaqList: [],
    };
  },
  created() {},
  mounted() {
    if (sessionStorage.getItem("askQuestion") != null) {
      this.askQuestion = sessionStorage.getItem("askQuestion");
    }

    if (this.hasInitialInfo) {
      this.gender_has_other_option = this.initialInfo.gender_has_other_option;
    }

    this.getRelatedFaqs();
  },
  methods: {
    saveQuestion() {
      sessionStorage.setItem("askQuestion", this.askQuestion);
    },
    onSubmit() {
      this.errorShow = false;
      if (this.requiredQuestion.gender_value == undefined) {
        this.errorShow = true;
        return false;
      }

      if (this.requiredQuestion.age_value == undefined) {
        this.errorShow = true;
        return false;
      }

      if (this.requiredQuestion.is_pregnant == undefined) {
        this.errorShow = true;
        return false;
      }

      if (this.requiredQuestion.is_sexually_active == undefined) {
        this.errorShow = true;
        return false;
      }

      if (this.askQuestion == undefined || this.askQuestion == "") {
        this.errorShow = true;
        return false;
      }
      let loader = Vue.$loading.show();
      this.isActive = true;
      let senddata = {
        gender_value: this.requiredQuestion.gender_value,
        age_value: this.requiredQuestion.age_value,
        is_pregnant: this.requiredQuestion.is_pregnant,
        is_sexually_active: this.requiredQuestion.is_sexually_active,
        question: this.askQuestion,
        user_country: this.initialInfo.currentLanguage.id,
      };

      this.$apiService.saveQuestion(senddata).then((res) => {
        if (res.data.success) {
          this.isActive = false;
          loader.hide();
          this.$router.push("/success/" + res.data.data.id);
        }
      })
      .catch((e) => {
          loader.hide();
          this.errors.push(e);
      });
    },
    getRelatedFaqs() {
      let params = {
        category_limit: 4,
        question: this.askQuestion,
      };

      this.$apiService.getRelatedFaqs(params).then((res) => {
        this.relatedFaqList = res.data.data;
      });
    },
  },
  destroyed() {
    sessionStorage.removeItem("askQuestion");
  },
};
</script>
<style>
.vld-overlay .vld-background {
  background: rgb(255 251 244 / 80%) !important;
}
</style>