<template>
  <header
    v-if="!['redirectPage'].includes($route.name)" 
    :class="{ 'home-header': homeBackgroundColour }"
  >
    <v-idle
      @idle="onidle"
      :loop="true"
      :duration="this.time_duration"
    />
    <div class="container">
      <b-button
        class="safety_msg" 
        id="show-btn"
        @click="showSafetyModal"
      >
        {{ this.safety_msg }}
      </b-button>
      <b-navbar toggleable="lg">
        <b-navbar-brand
          to="/"
          class="navbar-brand"
        >
          <img
            src="../assets/images/logo.png"
            alt="AskDr.Figo"
          >
        </b-navbar-brand>
        <b-navbar-toggle
          class="navbar-toggler"
          id="mobileMenu"
          type="button"
          target="nav-collapse"
        >
          <template>
            <i class="fa fa-bars" />
            <b class="open">Menu</b>
            <b class="close">Close</b>
          </template>
        </b-navbar-toggle>
        <b-collapse
          is-nav
          id="nav-collapse"
        >
          <b-navbar-nav class="ml-auto topmenu">
            <b-nav-item to="/about-dr-figo">
              {{ $t("global_page.about_dr_figo") }}
            </b-nav-item>
            <b-nav-item to="/emergency-help">
              {{ $t("global_page.emergency_help_and_resources") }}
            </b-nav-item>
            <b-nav-item>
              <button
                type="button"
                class="exit_button"
                @click="quickexit()"
              >
                {{ this.quick_exit }}
                <span class="quick_text">{{ this.quick_exit_text }}</span>
              </button>
            </b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="navbar-nav main ml-auto">
            <b-nav-item to="/">
              {{ $t("global_page.home") }}
            </b-nav-item>
            <b-nav-item-dropdown
              class="learn-from-dropdown"
              :text="$t('global_page.learn_from_dr_figo')"
            >
              <b-dropdown-item to="/learn-from-dr-figo">
                {{ $t("global_page.explore_all_information") }}
              </b-dropdown-item>
              <b-dropdown-item to="/personal-health-basics">
                {{ $t("global_page.see_personal_health_basics") }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item
              v-if="isEnabled"
              to="/retrieve-an-answer"
            >
              {{ $t("global_page.retrieve_an_answer") }}
            </b-nav-item>
            <b-nav-item-dropdown
              class="languagebtn"
              :text="
                `${
                  language.language
                    ? language.language_localised || language.language
                    : $t('global_page.read_in')
                }`
              "
              v-if="languageList.length > 0"
            >
              <b-dropdown-item
                v-for="(lang, index) of languageList"
                :key="index"
                :active="lang.id === language.id"
                @click="selectLanguage(lang)"
              >
                {{ lang.language_localised || lang.language }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <button
        type="button"
        class="exit_button exit_button_mobile"
        @click="quickexit()"
      >
        {{ this.quick_exit }}
        <span class="quick_text">{{ this.quick_exit_text }}</span>
      </button>
    </div>
    <b-modal
      ref="my-modal"
      hide-footer
      hide-header
      title=""
    >
      <div class="d-block text-center">
        <span v-html="safety_content" />
      </div>
      <!-- <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideSafetyModal"
      >
        Close Me
      </b-button> -->
    </b-modal>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      menuShow: true,
      isEnabled: true,
      homeBackgroundColour: false,
      quick_exit_text: "",
      quick_exit: "",
      websiteurl: "https://www.google.com/search?q=weather",
      safety_msg: "",
      safety_content: "",
      time_duration: 300// default 5 min
    };
  },
  props: {
    language: {
      type: Object,
      default() {
        return {};
      },
    },
    languageList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  created: function() {},
  mounted() {
    // redirect to safe page
    if (window.location.href.indexOf("redirect-page") > -1) {
      document.title = "Reloading....";
      var countryId = "";
      var params = window.location.pathname.split("redirect-page/");
      if (params[1]) {
        countryId = params[1];
      }
      if (localStorage.getItem("url_" + countryId) !== null) {
         console.log(localStorage.getItem("url_" + countryId));
         location.replace(localStorage.getItem("url_" + countryId));
      } else {
        location.replace("https://www.google.com/search?q=weather");
      }
      return false;
    }
    this.$eventBus.$on("initial-data-event", (data) => {
      this.getQuickExit();
    });

    this.$eventBus.$on("page-data-event", (data) => {
      this.getQuickExit();
    });

    if (this.hasInitialInfo) {
      this.getQuickExit();
    }
    this.$eventBus.$on("initial-data-event", (data) => {
      this.isEnabled = data.is_enabled;
    });

    if (this.hasInitialInfo) {
      this.isEnabled = this.initialInfo.is_enabled;
    }

    let currentUrl = window.location.pathname;

    if (currentUrl == "/") {
      this.homeBackgroundColour = true;
    } else {
      this.homeBackgroundColour = false;
    }
  },
  methods: {
    openMenu() {
      if (this.menuShow) {
        this.menuShow = false;
        document.getElementById("mobileMenu").classList.remove("collapsed");
        document.getElementById("navbarTogglerDemo02").classList.add("show");
      } else {
        this.menuShow = true;
        document.getElementById("mobileMenu").classList.add("collapsed");
        document.getElementById("navbarTogglerDemo02").classList.remove("show");
      }
    },
    closeMenu() {
      this.menuShow = true;
      document.getElementById("mobileMenu").classList.add("collapsed");
      document.getElementById("navbarTogglerDemo02").classList.remove("show");
    },
    selectLanguage(lan) {
      this.$emit("languageSelected", lan);
    },
    quickexit() {
      for (var i = 0; i <= window.history.length; i++) {
        window.history.pushState(null, null, "/redirect-page/"+this.initialInfo.currentLanguage.id);
      }
      location.replace(this.websiteurl);
    },
    getQuickExit() {
      this.$apiService
        .getQuickExit({
          countryId: this.initialInfo.currentLanguage.id,
        })
        .then((res) => {
          this.quickexistData = res.data.data;
          if (this.quickexistData){
            if (this.quickexistData.quick_exit) {
              this.quick_exit = this.quickexistData.quick_exit;
            }
            if (this.quickexistData.website) {
              this.websiteurl = this.quickexistData.website;
              if(this.initialInfo.currentLanguage.id && this.initialInfo.currentLanguage.id != undefined){
                localStorage.setItem("url_"+this.initialInfo.currentLanguage.id,this.websiteurl);
              }
            }
            if (this.quickexistData.quick_exit_text) {
              this.quick_exit_text = this.quickexistData.quick_exit_text;
            }
            if (this.quickexistData.safety_msg) {
              this.safety_msg = this.quickexistData.safety_msg;
            }
            if (this.quickexistData.safety_content) {
              this.safety_content = this.quickexistData.safety_content;
            }
            if (this.quickexistData.time_duration) {
              this.time_duration = this.quickexistData.time_duration * 60;
            }
          }
        });
    },
    onidle() {
      console.log("You have been logged out");
      location.replace(this.websiteurl);
    },
    showSafetyModal() {
      this.$refs["my-modal"].show();
    },
    hideSafetyModal() {
      this.$refs["my-modal"].hide();
    },
  },
  watch: {
    $route(to, from) {
      let currentUrl = window.location.pathname;

      if (currentUrl == "/") {
        this.homeBackgroundColour = true;
      } else {
        this.homeBackgroundColour = false;
      }
    },
  },
};
</script>